const HEADER_ID = 'header';
const CONTENT_ID = 'siteContent';
const COVID_BANNER_ID = 'covid-banner';

const ELEMENTS = {
  HEADER_ID,
  CONTENT_ID,
  COVID_BANNER_ID,
};

const TABLET_BREAKPOINT = 768;
const LARGE_TABLET_BREAKPOINT = 992;
const DESKTOP_BREAKPOINT = 1024;
const LARGE_DESKTOP_BREAKPOINT = 1200;

const LOCAL_STORAGE_PRODUCTS_KEY = 'recentlyViewedProducts';
const RECENT_PRODUCTS_LENGTH = 6;

const DEFAULT_PRODUCT_FAMILY_COLOR = 'teal';
const LABEL_DARK_FONT_TEXT = 'navy';
const LABEL_LIGHT_FONT_TEXT = 'white';

const COVID_BANNER_TYPE = {
  sticky: 'sticky',
  pinned: 'pinned',
};

const COVID_BANNER_POSITION = {
  top: 'top',
  bottom: 'bottom',
};

const NEWSLETTER_POPUP_COOKIE = 'NEWSLETTER_POPUP';
const NEWSLETTER_MODE: { [key in 'FORM' | 'THANKS']: NewsletterTypes.TNewsLetterViewMode } = {
  FORM: 'FORM_MODE',
  THANKS: 'THANKS_MODE',
};

const NEWSLETTER_FORM_ACTION: {
  [key in 'SUBMITTED' | 'CLOSED']: NewsletterTypes.TNewsletterCookieAction;
} = {
  SUBMITTED: 'submitted',
  CLOSED: 'closed',
};

const INTERACTIVE_IMG_TYPE: Record<string, CampaignPageTypes.TInteractiveImgType> = {
  OSTEOARTHRITIS: 'osteoarthritis',
  NUROMOL: 'nuromol',
};

const MONTH_LONG = 'long';
const DATE_TYPE = 'ordinal';

export {
  ELEMENTS as default,
  TABLET_BREAKPOINT,
  LARGE_TABLET_BREAKPOINT,
  DESKTOP_BREAKPOINT,
  LARGE_DESKTOP_BREAKPOINT,
  LOCAL_STORAGE_PRODUCTS_KEY,
  RECENT_PRODUCTS_LENGTH,
  DEFAULT_PRODUCT_FAMILY_COLOR,
  COVID_BANNER_TYPE,
  COVID_BANNER_POSITION,
  LABEL_DARK_FONT_TEXT,
  LABEL_LIGHT_FONT_TEXT,
  NEWSLETTER_POPUP_COOKIE,
  NEWSLETTER_MODE,
  NEWSLETTER_FORM_ACTION,
  INTERACTIVE_IMG_TYPE,
  MONTH_LONG,
  DATE_TYPE,
};
