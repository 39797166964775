import { isBrowser } from 'utils/browser';
import { capitalize } from 'utils/stringUtils/capitalize';
import { getStringWithoutSymbols, isEmpty } from 'utils/helpers';
import {
  GTMEvent,
  IGTMProduct,
  IProductItem,
  PageViewEventType,
  GenerateLeadEventType,
  SearchResultsViewEventType,
  RetailerClickEventType,
  ProductViewEventType,
  ProductListingViewEventType,
} from './model';

class GtmService {
  private eventKeys = {
    event: 'event',
    pageTemplate: 'page_template',
    userId: 'user_id',
    formName: 'form_name',
    searchTerm: 'search_term',
    retailerName: 'retailer_name',
  } as const;

  private eventValues = {
    generateLead: 'generate_lead',
    viewSearchResults: 'view_search_results',
    retailerLinkClick: 'retailer_link_click',
    viewItem: 'view_item',
    viewItemList: 'view_item_list',
  } as const;

  private productKeys = {
    itemId: 'item_id',
    itemName: 'item_name',
    itemBrand: 'item_brand',
    itemVariant: 'item_variant',
    itemCategory: 'item_category',
    itemListName: 'item_list_name',
    itemCategory2: 'item_category2',
  } as const;

  formNames = {
    newsletter: 'newsletter',
    contactUs: 'contact_us',
    competition: 'competition',
    coupon: 'coupon',
    other: 'other',
  } as const;

  private templateNames = {
    home: 'homepage',
    article: 'article_page',
    product: 'product_page',
    productListing: 'products_list_page',
    search: 'search_results_page',
    contactUs: 'contact_us_page',
    other: 'other_page',
  } as const;

  readonly brandName = 'Nurofen';

  readonly iframeMessageFormSubmitted = 'FORM_SUBMITTED';

  readonly delayTimeout = parseInt(process.env.GATSBY_GOOGLE_TAG_TIMEOUT || '', 10) + 1000 || 3000;

  emitPageView(templateName: string = ''): void {
    const pageTemplate = (Object.values(this.templateNames) as string[]).includes(templateName)
      ? templateName
      : this.templateNames.other;

    const event: PageViewEventType = {
      [this.eventKeys.pageTemplate]: pageTemplate,
    };

    this.emitEvent(event);
  }

  emitSearchResultsView(searchTerm: string): NodeJS.Timeout | void {
    if (typeof searchTerm !== 'string' || !searchTerm.trim()) return;

    const event: SearchResultsViewEventType = {
      [this.eventKeys.event]: this.eventValues.viewSearchResults,
      [this.eventKeys.searchTerm]: searchTerm.trim().toLowerCase(),
    };

    return this.emitDelayedEvent([event]);
  }

  emitGenerateLead(formName: string): NodeJS.Timeout {
    const event: GenerateLeadEventType = {
      [this.eventKeys.event]: this.eventValues.generateLead,
      [this.eventKeys.formName]: formName || this.formNames.other,
    };

    return this.emitDelayedEvent([event]);
  }

  emitRetailerClick(product: IProductItem, retailerName?: string): void {
    if (typeof retailerName !== 'string' || !retailerName.trim()) return;

    const event: RetailerClickEventType = {
      [this.eventKeys.event]: this.eventValues.retailerLinkClick,
      [this.eventKeys.retailerName]: retailerName.toLowerCase(),
      ...(!isEmpty(product) && {
        ecommerce: {
          items: [this.getGtmProductItem(product)],
        },
      }),
    };

    this.emitEvent({ ecommerce: null });
    this.emitEvent(event);
  }

  emitProductView(product: IProductItem): NodeJS.Timeout | void {
    if (isEmpty(product)) return;

    const gtmProductItem: IGTMProduct = this.getGtmProductItem(product);
    const event: ProductViewEventType = {
      [this.eventKeys.event]: this.eventValues.viewItem,
      ecommerce: {
        items: [gtmProductItem],
      },
    };

    return this.emitDelayedEvent([{ ecommerce: null }, event]);
  }

  emitProductListingView(listingName?: string, products: IProductItem[]): NodeJS.Timeout | void {
    if (typeof listingName !== 'string' || !listingName.trim() || isEmpty(products)) return;

    const gtmProductItems = products.map(this.getGtmProductItem.bind(this));
    const event: ProductListingViewEventType = {
      [this.eventKeys.event]: this.eventValues.viewItemList,
      [this.productKeys.itemListName]: capitalize(listingName),
      ecommerce: {
        items: gtmProductItems,
      },
    };

    return this.emitDelayedEvent([{ ecommerce: null }, event]);
  }

  private getGtmProductItem({
    pageName,
    title,
    defaultProductTitle,
    sku,
    dropdownValue,
    preferred,
    productVariant,
    Format,
    ProductCategory,
  }: IProductItem): IGTMProduct {
    const productTitle = title || defaultProductTitle || pageName || '';
    const productCategory = preferred?.[0].title || ProductCategory?.[0]?.title;
    const productVariantToSend = dropdownValue || productVariant || Format?.[0]?.title;

    return {
      [this.productKeys.itemBrand]: this.brandName,
      [this.productKeys.itemName]: capitalize(getStringWithoutSymbols(productTitle)).trim(),
      ...(sku && { [this.productKeys.itemId]: sku }),
      ...(productCategory && {
        [this.productKeys.itemCategory]: capitalize(productCategory).trim(),
      }),
      ...(productVariantToSend && {
        [this.productKeys.itemVariant]: capitalize(productVariantToSend).trim(),
      }),
    };
  }

  // eslint-disable-next-line class-methods-use-this
  private emitEvent(event: GTMEvent): void {
    if (!isBrowser()) return;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  }

  private emitDelayedEvent(events: GTMEvent[]): NodeJS.Timeout {
    return setTimeout(() => {
      events.forEach((event) => {
        this.emitEvent(event);
      });
    }, this.delayTimeout);
  }
}

// eslint-disable-next-line import/prefer-default-export
export const gtmService = new GtmService();
