const ReactDOM = require('react-dom');
const { loadableReady } = require('@loadable/component');

const { gtmService } = require('./src/services/gtmService');

exports.replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.hydrate(element, container, callback);
    });
  };
};

exports.shouldUpdateScroll = () => {
  if (!window._disableUpdateScroll) {
    window.scrollTo(0, 0);
  }

  return false;
};

exports.wrapPageElement = ({ props }) => {
  const { pageTemplate } = props.pageContext || {};
  gtmService.emitPageView(pageTemplate);
};
